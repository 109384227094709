import { useStaticQuery, graphql } from "gatsby"
import React, { useState } from "react"
import * as yup from 'yup'

import Layout from "../components/layout"
import SeoMeta from "../components/seo"

import * as css from "./contact-us.module.scss"

const formSchema = yup.object().shape({
  name: yup.string().required().label('Your Name'),
  email: yup.string().email().required().label('Email Address'),
  message: yup.string().required().label('Message')
});

const ContactForm = props => {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const handleSubmit = event => {
    event.preventDefault()
    const form = event.target
    const formValues = {
      name: form.nameInput.value,
      email: form.emailInput.value,
      message: form.messageInput.value,
    }

    const reportSuccess = () => { setSuccess(true); setError(false) }
    const reportError = () => { setSuccess(false); setError(true) }

    formSchema.validate(formValues).then(function (validatedValues) {
      fetch('/api/send', {
        method: 'post',
        headers : new Headers({
          'Content-Type': 'application/json; charset=UTF-8'
        }),
        body: JSON.stringify(validatedValues)
      }).then(response => {
        response.status === 200 ? reportSuccess() : reportError()
      }).catch(e => {
        console.error(e)
        setError(true)
      })
    }).catch(e => {
      console.error(e)
      setError({ message: [e.name, e.errors.toString()].join(': ')})
    })
  }
  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className={`card-text form-intro`}>
          <form method="post" action="#top" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="nameInput">Your name</label>
              <input disabled={success} aria-label="Your name" type="text" className="form-control" id="nameInput" placeholder="Your name" />
            </div>
            <div className="form-group">
              <label htmlFor="emailInput">Email address</label>
              <input disabled={success} aria-label="Your email" type="email" className="form-control" id="emailInput" placeholder="Your email" />
            </div>
            <div className="form-group">
              <label htmlFor="messageInput">Message</label>
              <textarea disabled={success} aria-label="Your message" className="form-control" id="messageInput" placeholder="Your message" rows="3"></textarea>
            </div>
            <button type="submit" disabled={success} className="btn btn-primary">Submit</button>
            <span className={`${success ? 'form-success text-success' : error ? 'form-error text-danger' : 'd-none'}`}>
              &nbsp;&nbsp;&nbsp;
              { success ? 'Thank you! Your message has been sent.' : '' }
              { error ? error?.message || "There was an error sending your message." : '' }
            </span>
          </form>
        </div>
      </div>
    </div>
  )
}

const Telephone = props => {
  const { number } = props || ['000','000','0000']
  const numberHref = `tel:+1${number.join('')}`
  const handleClick = e => {
    const ready = window.confirm('Ready to call?')
    if (!ready) e.preventDefault()
  }
  return (
    <a href={numberHref} onClick={handleClick} rel="nofollow">({number[0]}) {number[1]}-{number[2]}</a>
  )
}

const HoursGrid = props => {
  const { hours } = props
  const gridStyle = {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    gridTemplateColumns: 'auto 1fr',
    gridGap: '.2rem 2rem',
    marginBottom: '1rem',
  }
  return (
    <div style={gridStyle}>
      { hours.map((hour, idx) => {
        return (
          <React.Fragment key={`${hour[0]}`}>
            <div className="grid-cell"><strong>{hour[0]}</strong></div>
            <div className="grid-cell"><span>{hour[1]}</span></div>
          </React.Fragment>
        )
      })}
    </div>
  )
}

const ContactUsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          address
          city
          state
          zip
          phone
        }
      }
    }
  `)
  const meta = data.site.siteMetadata
  return (
    <Layout>
      <SeoMeta title="Contact Us" />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3>Contact Us</h3>
            <p>Valley School Early Childhood Center</p>
            <a href="https://goo.gl/maps/M1Dk1eDHE44kZUDs6">
              <address>
                {meta.address}<br />{meta.city}, {meta.state} {meta.zip}
              </address>
            </a>
            <p>
              <Telephone number={meta.phone} />
            </p>
            <h3>Hours</h3>
            <HoursGrid hours={[
              ['Office', '8:00am - 4:30pm'],
              ['Campus', '6:30am - 6:30pm'],
            ]} />
            <h3>Schedule a Tour</h3>
            <p>We would love for you to set up a private tour of our school. We look forward to meeting you and your family! To set up an appointment, simply call us during office hours or use the email form below and the Preschool Director will call you within the next business day.</p>
            <h3>Contact us via email</h3>
            <ContactForm />
            <h3>Location</h3>
            <p>Valley School is located in Los Angeles County, just one block west of the 405 Freeway, on the south-west corner of Sherman Way and Haskell Ave. Because of the school’s central location, Valley School students commute from a wide variety of locations.</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactUsPage
